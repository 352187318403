import type { SendableMessage, ReceivableMessage, EventData } from "../types";
import { eraseCookie } from "./cookieHelper";
import Bugsnag from "./bugsnag";
import { getGenericTrackingData } from "@finanzcheck/one-segment";

const CHILD_FRAME_NAME = "loan_application_widget";
const urlParams = new URLSearchParams(window.location.search);

function logEvent(eventName: "lead" | "all_lead") {
  const urlMap = {
    lead: `https://${process.env.SEGMENT_HOST}/bkkje8ksjklkwllsaiw.json`,
    all_lead: `https://${process.env.SEGMENT_HOST}/bkkje8ksjklkwllsaiw_all_lead.json`,
  };
  fetch(urlMap[eventName], {
    method: "GET",
    credentials: "include",
    mode: "no-cors",
  }).catch((err: any) => {
    Bugsnag.notify(err, (errorEvent) => {
      errorEvent.context = "Log request failed";
      errorEvent.addMetadata("log url", {
        url: `https://${process.env.SEGMENT_HOST}/bkkje8ksjklkwllsaiw.json`,
      });
    });
  });
}

function sendFrameMessage<
  N extends SendableMessage["eventName"],
  D extends EventData<N>,
>(eventName: N, data?: D) {
  const message = {
    eventName,
    data: data || {},
  };

  try {
    const childFrame = window.document.querySelector<HTMLIFrameElement>(
      "iframe" + (CHILD_FRAME_NAME ? `[name="${CHILD_FRAME_NAME}"]` : ""),
    );
    if (!childFrame || !childFrame.contentWindow) {
      throw new Error(
        !childFrame
          ? `Could not find the targeted iframe: "${CHILD_FRAME_NAME}".`
          : "Could not access iframe window object.",
      );
    }
    childFrame.contentWindow.postMessage(JSON.stringify(message), "*");
  } catch (error) {
    // console.error('broadcast to child:', error);
  }
}

function handleMessage(event: MessageEvent) {
  try {
    if (!event.data) return;

    let message: ReceivableMessage | null = event.data;
    try {
      if (typeof event.data === "string") {
        message = JSON.parse(event.data);
      }
    } catch (e) {
      // parsing the string failed so we expect this is not a JSON string but a plain string
    }
    if (!message?.eventName) return;

    switch (message.eventName) {
      case "ready":
        // sending 'ready' back is already handled by FrameMan
        const regRouteContextQueryParams = {
          header_variant: urlParams.get("header_variant") ?? "none",
          bankpartner: urlParams.get("bankpartner") ?? "kredit2day",
          embed: urlParams.get("embed") ?? "true",
          embedded: urlParams.get("embedded") ?? "true",
        };
        sendFrameMessage("setPageQueryParams", {
          ...regRouteContextQueryParams,
        });
        sendFrameMessage(
          "parentFrameStartLoadDate",
          window.regRouteFrameLoaded,
        );
        // send our own inital page track
        const pageTrackingObject = getGenericTrackingData();
        window.analytics.page(pageTrackingObject);
        break;

      case "trackPageVisit": {
        const { ...payload } = message.data;
        const pageTrackingObject = getGenericTrackingData();
        window.analytics.page({ ...payload, ...pageTrackingObject });
        break;
      } // braces needed for variable redeclaration

      case "tracking": {
        const { event, context, ...payload } = message.data;
        if (event === "lead" || event === "all_lead") {
          logEvent(event);
        }
        window.analytics.track(event, payload, context);
        break;
      } // braces needed for variable redeclaration

      case "redirect":
        // @TODO: We might want to have a whitelist here to protect from unwanted urls
        window.location.assign(message.data?.url);
        break;

      case "getParentFrameUrl":
        const url = window.location.href;
        sendFrameMessage("setParentFrameUrl", { url });
        break;

      case "callSegmentIdentify": {
        const { ...payload } = message.data;
        window.analytics.identify(payload);
        break;
      }

      case "deleteCookie":
        eraseCookie(message.data.cookieName);
        break;

      default:
        break;
    }
  } catch (err: any) {
    Bugsnag.notify(err, (errorEvent) => {
      errorEvent.context =
        "Unexpected message format when processing in registration route frame";
      errorEvent.addMetadata("event data", { eventData: event.data });
    });
  }
}

window.addEventListener("message", handleMessage);
