import cookie, { CookieSerializeOptions } from "cookie";

const cookieBaseUrl = process.env.COOKIE_BASE_URL;

export const getCookie = (name: string): string | undefined => {
  let cookieValue = undefined;
  if (typeof document !== "undefined" && navigator && navigator.cookieEnabled) {
    const cookies = cookie.parse(document.cookie);
    if (!name) {
      return undefined;
    }
    if (cookies[name]) {
      cookieValue = cookies[name];
    }
  }
  return cookieValue;
};

export const setCookie = (
  cname: string,
  cvalue: string,
  options: CookieSerializeOptions = {},
) => {
  if (typeof document !== "undefined" && navigator && navigator.cookieEnabled) {
    const defaultOptions = {
      maxAge: 365 * 24 * 60 * 60, // 356 days in seconds
      path: "/",
      domain: cookieBaseUrl || undefined,
    };

    const mergedOptions: CookieSerializeOptions = {
      ...defaultOptions,
      ...options,
    };

    document.cookie = cookie.serialize(cname, cvalue, mergedOptions);

    // if on localhost, also write the cookie on localhost to enable
    // hybrid work with shared plus localhost
    if (window.location.hostname === "localhost") {
      const localMergedOptions = { ...mergedOptions };
      delete localMergedOptions.domain;
      document.cookie = cookie.serialize(cname, cvalue, localMergedOptions);
    }
  }
};

export const eraseCookie = (cname: string) => {
  const deletedValue = "";

  const options = {
    maxAge: -1,
    expires: new Date("Thu, 01 Jan 1970 00:00:00 GMT"),
    domain: cookieBaseUrl || undefined,
    path: "/",
  };

  document.cookie = cookie.serialize(cname, deletedValue, options);
  // if on localhost, also write the cookie on localhost to enable
  // hybrid work with shared plus localhost
  if (window.location.hostname === "localhost") {
    const localOptions = { ...options };
    delete localOptions.domain;
    document.cookie = cookie.serialize(cname, deletedValue, localOptions);
  }

  console.info("Deleted Cookie", cname, cookieBaseUrl);
};
