import initOneSegment, { cookieBannerSnippet } from "@finanzcheck/one-segment";
import urlParamsTranslator from "./lib/urlParamsConverter";
import "./lib/frameMessageHandler";
import { setFcidIfNotPresent } from "./lib/fcid";
import { runAppContext } from "./lib/appContext";

declare global {
  const ffg_embed_iframe: (args: { [key: string]: any }) => void;
  interface Window {
    frameman: {
      load: (a: HTMLElement | null, b: any) => void;
    };
  }
}

type loadFramemanProps = {
  amount?: string;
  term?: string;
  purpose?: string;
  advertisementId?: string;
  formConfig?: string;
  backUrl?: string;
  context?: string;
};

const AFFILIATE = "affiliate";
const APP = "app";

function loadFrameman(smavaParams: loadFramemanProps) {
  const advertisementId =
    smavaParams.advertisementId || process.env.ADVERTISMENT_ID;
  const entryPoint = "baseReceived";
  const decodedBackUrl = smavaParams.backUrl
    ? decodeURIComponent(smavaParams.backUrl)
    : "";

  const sessionStorageNavigationObject = JSON.parse(
    `${
      window.sessionStorage
        ? window.sessionStorage.getItem("IMOD_CURRENT_NAVIGATION")
        : "{}"
    }`,
  );

  window.frameman.load(document.getElementById("iframe-placeholder"), {
    src: appendUrlParams(
      sessionStorageNavigationObject?.url || process.env.TAURINE_URL || "",
      advertisementId || "",
      entryPoint,
      {
        amount: smavaParams.amount || "20000",
        term: smavaParams.term || "84",
        purpose: smavaParams.purpose || "OTHER",
        formConfig: smavaParams.formConfig || "ddf_smava",
        ...(smavaParams.context && { context: smavaParams.context }),
      },
    ),
    iframeName: "loan_application_widget",
    storage: "localStorage",
    deactivateHubble: smavaParams.context === AFFILIATE ? false : true,
    env: process.env.REGISTRATION_FRAMEMAN_ENVIRONMENT,
    dataHjAllowIframe: false,
    backUrl: decodedBackUrl,
  });

  if (smavaParams.context === APP) {
    runAppContext();
  }
}

function appendUrlParams(
  url: string,
  advertisementId: string,
  entryPoint: string | undefined,
  urlPayload: { [key: string]: string },
): string {
  const urlParams = new URLSearchParams(document.location.search.substring(1));
  urlParams.set("advertisementId", advertisementId);
  if (entryPoint) urlParams.set("entryPoint", entryPoint);

  for (const [name, value] of Object.entries<string>(urlPayload)) {
    urlParams.set(name, value);
  }
  if (url.indexOf("?") > -1) {
    console.log(`${url}&${urlParams.toString()}`);
    return `${url}&${urlParams.toString()}`;
  }
  console.log(`${url}?${urlParams.toString()}`);
  return `${url}?${urlParams.toString()}`;
}

window.addEventListener("load", function load() {
  const params = new URLSearchParams(window.location.search);
  const paramsObject: any = {};
  params.forEach((value, key) => {
    paramsObject[key] = value;
  });

  var smavaParams = urlParamsTranslator(paramsObject);
  loadFrameman(smavaParams);

  setFcidIfNotPresent();

  // Show cookie banner and startup Segment
  eval(cookieBannerSnippet());
  // Note: the page will error on inital page track true as it loads too fast.
  // We therefore need to manually page track.
  initOneSegment(process.env.SEGMENT_WRITE_KEY, {
    debug: process.env.NODE_ENV === "development",
    segmentHost: process.env.SEGMENT_HOST,
    initialPageTrack: false,
    sessionCookieDomain: process.env.COOKIE_BASE_URL,
    teamName: "catapult",
  });
});
