export const runAppContext = () => {
  const footerDomNode = document.getElementsByTagName("footer")[0];
  const headerDomNode = document.getElementsByTagName("header")[0];

  if (footerDomNode && footerDomNode.parentNode) {
    footerDomNode.parentNode.removeChild(footerDomNode);
  }

  if (headerDomNode && headerDomNode.parentNode) {
    headerDomNode.parentNode.removeChild(headerDomNode);
  }
};
