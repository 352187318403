import { getCookie, setCookie } from "./cookieHelper";

export function generateFCID(length = 26) {
  return window
    .btoa(
      Array.from(window.crypto.getRandomValues(new Uint8Array(length * 2)))
        .map((b) => String.fromCharCode(b))
        .join(""),
    )
    .replace(/[+/]/g, "")
    .substring(0, length);
}

export function setFcidIfNotPresent() {
  const isFcid = !!getCookie("FCID");
  if (!isFcid) {
    const newFcid = generateFCID();
    const options = {
      maxAge: 365 * 24 * 60 * 60, // 356 days in seconds
      path: "/",
      domain: process.env.COOKIE_BASE_URL || undefined,
      secure: true,
    };
    setCookie("FCID", newFcid, options);
  }
}
