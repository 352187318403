export enum SMAVA_CATEGORY {
  FREE_USE = 888,
  CAR = 887,
  LIVING = 886,
  MORTGAGE = 999999999,
  RESCHEDULE = 8992946,
  BUSINESS = 40895887,
}

export enum FINANZCHECK_PURPOSE {
  REFINANCING = "REFINANCING",
  PRE_OWNED_CAR = "PRE_OWNED_CAR",
  RENOVATION = "RENOVATION",
  REAL_ESTATE_FINANCING = "REAL_ESTATE_FINANCING",
  OTHER = "OTHER",
  OTHER_GEWERBE = "OTHER_GEWERBE",
}
