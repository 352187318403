import Bugsnag from "@bugsnag/js";

const appVersion = process.env.VERCEL_GIT_COMMIT_SHA
  ? `${process.env.VERCEL_GIT_COMMIT_SHA}`
  : "development";

Bugsnag.start({
  apiKey: process.env.BUGSNAG_BROWSER_API_KEY || "",
  collectUserIp: false,
  enabledReleaseStages: ["live", "stage", "shared"],
  releaseStage: process.env.BUGSNAG_RELEASE_STAGE,
  appVersion,
});

export default Bugsnag;
